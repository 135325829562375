import { ViewData } from 'cccisd-laravel-assignment';
import React from 'react';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import whitelistQuery from './whitelistQuery.graphql';

export default class ViewDataPage extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    state = {
        isLoading: true,
        whitelist: [],
    };

    componentDidMount() {
        this.getWhitelist();
    }

    /**
     * Get the deployments that are relevant (hide parent deployments)
     */
    getWhitelist = async () => {
        const response = await apollo.query({
            query: whitelistQuery,
            fetchPolicy: 'network-only',
            variables: {},
        });

        const whitelist = response.data.flows.deploymentList.map(d => {
            return d.deploymentId;
        });

        await this.setState({ isLoading: false, whitelist });
    };

    render() {
        const { isLoading, whitelist } = this.state;

        if (isLoading) {
            return <Loader loading />;
        }
        return (
            <ViewData
                projectId={1}
                hideProjectDropdown
                labelForDeployment="Time Point"
                labelForProjectPlural="Programs"
                labelForQuest="Survey"
                labelForSurvey="Section"
                deploymentIdWhitelist={whitelist}
            />
        );
    }
}
